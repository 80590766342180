<template>
  <div class="driver-cards">

    <div class="driver-card-wrapper" v-for="account in data.item.wallet.accounts">
      <div class="driver-card"
           v-if="account.currentCard"
           v-b-popover.hover.left.html="getCardText(account)"
           :title="account.typeAccount == 1 ? 'Готівкова картка' : account.typeAccount == 2 ? 'Безготівкова картка' : ''"
           variant="outline-primary"
      >
        <img class="driver-card-image" src="/cashcard.png" alt="" v-if="account.typeAccount == 1">
        <img class="driver-card-image" src="/cashlesscard.png" alt="" v-else-if="account.typeAccount == 2">
        <div class="driver-card-balance" v-if="account.currentCard.isLimit">Ліміти</div>
        <div class="driver-card-balance" v-if="!isNaN(account.balance)">{{ centsToDollars(account.balance) }} грн</div>
      </div>
    </div>

    <div class="driver-card empty"
         @click="openModal(data.item)"
         v-if="checkAllowedAccountTypes(data, data.item.wallet.accounts)"
         tabindex="0"
    >
      +
    </div>
  </div>
</template>

<script>
import { useBusinessSettings } from "@/composition/businessSettings/useBusinessSettings";
import {VBPopover} from "bootstrap-vue";
import { centsToDollars } from "@/helpers/priseConvert";

export default {
  name: "AccountCards",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  emits: ["openModal"],

  directives: {
    'b-popover': VBPopover,
  },

  setup(props, {emit}) {
    const { accountTypes }  = useBusinessSettings();

    const openModal = (event) => {
      emit("openModal", event)
    };

    const getCardText = (account) => {
      const card = account.currentCard

      let text = ''

      if (card !== null) {

        if (!isNaN(account.balance)) {
          text += '<p><b>Баланс:</b> ' + centsToDollars(account.balance) + ' грн</p>'
        }

        card.isLimit = false

        // if (account.typeAccount == 2) {

        if (account.currentCard.limits.goods && account.currentCard.limits.money &&
            (account.currentCard.limits.goods.dayLimitActive || account.currentCard.limits.goods.weekLimitActive || account.currentCard.limits.goods.monthLimitActive ||
                account.currentCard.limits.money.dayLimitActive || account.currentCard.limits.money.weekLimitActive || account.currentCard.limits.money.monthLimitActive)
        ) {
          card.isLimit = true

          text += '<b>Ліміти:</b>'
          text += '<div class="limits-tip">'

          Object.entries(card.limits).forEach(limitItem => {
            const [type, limit] = limitItem

            if (type == 'money' && limit && (limit.dayLimitActive || limit.weekLimitActive || limit.monthLimitActive)) {

              text += 'Грошові:'

              if (limit.dayLimitActive) {
                text += '<p>На день: ' + (parseInt(limit.dayLimitRest) / 100).toFixed(2) + ' / ' + (parseInt(limit.dayLimitValue) / 100).toFixed(2) + ' грн</p>'
              }
              if (limit.weekLimitActive) {
                text += '<p>На тиждень: ' + (parseInt(limit.weekLimitRest) / 100).toFixed(2) + ' / ' + (parseInt(limit.weekLimitValue) / 100).toFixed(2) + ' грн</p>'
              }
              if (limit.monthLimitActive) {
                text += '<p>На місяць: ' + (parseInt(limit.monthLimitRest) / 100).toFixed(2) + ' / ' + (parseInt(limit.monthLimitValue) / 100).toFixed(2) + ' грн</p>'
              }
            }

            if (type == 'goods' && limit && (limit.dayLimitActive || limit.weekLimitActive || limit.monthLimitActive)) {

              text += 'Паливні:'

              if (limit.dayLimitActive) {
                text += '<p>На день: ' + parseInt(parseInt(limit.dayLimitRest) / 1000) + ' / ' + parseInt(parseInt(limit.dayLimitValue) / 1000) + ' л</p>'
              }
              if (limit.weekLimitActive) {
                text += '<p>На тиждень: ' + parseInt(parseInt(limit.weekLimitRest) / 1000) + ' / ' + parseInt(parseInt(limit.weekLimitValue) / 1000) + ' л</p>'
              }
              if (limit.monthLimitActive) {
                text += '<p>На місяць: ' + parseInt(parseInt(limit.monthLimitRest) / 1000) + ' / ' + parseInt(parseInt(limit.monthLimitValue) / 1000) + ' л</p>'
              }
            }
          });
          text += '</div>'
        }

        // }


        if (card.fuels.length) {
          text += '<b>Доступні види палива:</b><ul>'
          card.fuels.forEach(fuel => {
            text += '<li>' + fuel + '</li>'
          });
          text += '</ul>'
        }

      }

      return text;
    }

    const checkAllowedAccountTypes = (data, accounts) => {

      let isDriverStatusAllowToCreateRightNow = ['ACTIVE', 'CONFIRMED', 'PREPARED'].includes(data.item.status);
      if (!isDriverStatusAllowToCreateRightNow) {
        return false;
      }

      let collectedTypes = [];
      accounts.forEach(account => collectedTypes.push(account.typeAccount));

      let hasAnyTypeAccountAllowedToCreate = false;
      accountTypes?.value?.forEach(type => {
        if (!collectedTypes.includes(type)) {
          hasAnyTypeAccountAllowedToCreate = true;
        }
      });

      if (!hasAnyTypeAccountAllowedToCreate) {
        return false;
      }

      return true;
    };

    return {
      checkAllowedAccountTypes,
      openModal,
      getCardText,
      centsToDollars,
    };

  }

}
</script>

<style scoped lang="scss">
.driver-cards {
  display: flex;
  align-items: flex-start;
}

.driver-card {
  cursor: pointer;
  width: 70px;
// height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  margin-right: 10px;
}

.driver-card.empty {
  height: 40px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 800;
}

.driver-card-image {
  width: 60px;
  height: 37px;
  border-radius: 5px;
  object-fit: cover;
}

.driver-card-balance {
  margin-top: 3px;
}
</style>
